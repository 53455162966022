<template>
  <div class="pl-4">
    <div v-if="isDealerConfirmed">Проверка...</div>
    <div v-if="!loading && !isDealerConfirmed">
      <b-alert :show="showAlertByLeaderAgreed" variant="danger">
        Данные вашей учетной записи и торговой точки (кроме адреса) Вы можете изменить <b>в личном кабинете после подтверждения.</b><br />
        Для изменения <b>адреса торговой точки</b> свяжитесь с Производителем.
      </b-alert>
      <b-alert :show="showAlertByNotLeaderAgreed" variant="danger">
        Подтверждение данных вашей учетной записи будет возможно <b>после согласования</b> со стороны РЕХАУ.
      </b-alert>
      <h1 class="h4">Добавление партнера. Проверка данных.</h1>      
      <br />
      <ul class="mb-2 list">
        <li>
          <b>ИНН</b>
          <span>{{ this.partnerForVerify.inn }}</span>
        </li>
        <li>
          <b>Наименование</b>
          <span>{{ this.partnerForVerify.name }}</span>
        </li>
        <li>
          <b>Фактический адрес</b>
          <span>{{ this.partnerForVerify.address }}</span>
        </li>
        <li>
          <b>Контактное лицо</b>
          <span>{{ this.partnerForVerify.contactPerson }}</span>
        </li>
        <li>
          <b>Email</b>
          <span>{{ this.partnerForVerify.contactPersonEmail }}</span>
        </li>
        <li>
          <b>Телефон</b>
          <span>{{ this.partnerForVerify.contactPersonPhone }}</span>
        </li>
        <li>
          <b>Название офиса</b>
          <span>{{ this.partnerForVerify.addressCaption }}</span>
        </li>
        <li>
          <b>Менеджер офиса <br />продаж</b>
          <span>{{ this.partnerForVerify.accost }}</span>
        </li>
        <li>
          <b>Телефон офиса</b>
          <span>{{ this.partnerForVerify.addressPhone }}</span>
        </li>
        <li>
          <b>Сайт</b>
          <span>{{ this.partnerForVerify.webSite }}</span>
        </li>
      </ul>

      <b-button v-if="buttonsVisible" :disabled="buttonsDisabled" variant="danger" class="ml-4 mb-4" v-b-modal.modalMessage>Данные ошибочны</b-button>
      <b-button v-if="buttonsVisible" :disabled="buttonsDisabled" variant="success" class="ml-4 mb-4" @click="onDataCorrect">Подтверждаю</b-button>
      <span class="ml-4 mb-4 spinner" v-if="loadingData">
        <b-spinner variant="success" label="Spinning"></b-spinner>
      </span>

      <b-modal id="modalMessage" title="Введите сообщение" ok-title="Отправить" cancel-title="Отмена" @ok="sendMessage" @cancel="cancelMessage">
        <b-form-textarea v-model="message" placeholder="Перечислите что необходимо изменить" maxlength="500" rows="9"></b-form-textarea>
      </b-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";

export default {
  name: "PartnerVerify",
  data() {
    return {
      loading: true,
      loadingData: false,
      message: null,
      dealerStatus: 0,
      isLeaderAgreed: false
    };
  },
  methods: {
    ...mapActions("partners", ["dealerVerify", "dealerVerifyDetails", "dealerDataWrong", "dealerDataConfirm", "fetchPartner"]),
    onDataCorrect() {
      this.loadingData = true;
      this.dealerDataConfirm({ dealerId: this.partnerId }).then(() => {
        this.loadingData = false;
        this.$router.push("/offices");
      });
    },
    sendMessage() {
      let dealerData = {
        dealerId: this.partnerId,
        message: this.message,
      };
      this.loadingData = true;
      this.dealerDataWrong(dealerData).then(() => {
        this.dealerVerifyDetails().then(() => {
          this.dealerStatus = this.partnerForVerify.status;
          this.loadingData = false;
        });
      });
    },
    cancelMessage() {
      this.message = "";
    },
  },
  watch: {
    partnerForVerify(val) {
      this.dealerStatus = val.status;
      this.isLeaderAgreed = val.isLeaderAgreed;
    },
  },
  computed: {
    ...mapState({
      partnerId: (state) => state.auth.user.partnerId,
    }),
    ...mapState("partners", {
      isDealerConfirmed: (state) => state.confirmed,
      partnerForVerify: (state) => state.partnerForVerify,
    }),
    buttonsDisabled() {
      return this.dealerStatus == 1;
    },
    buttonsVisible() {
      return this.isLeaderAgreed;
    },
    showAlertByLeaderAgreed() {
      return this.isLeaderAgreed;
    },
    showAlertByNotLeaderAgreed() {
      return !this.isLeaderAgreed;
    }
  },

  created() {
    if (this.partnerId) {
      this.dealerVerify().then(() => {
        if (this.isDealerConfirmed) this.$router.push("/offices");
        else
          this.dealerVerifyDetails().then(() => {
            this.dealerStatus = this.partnerForVerify.status;
            this.loading = false;
          });
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>
